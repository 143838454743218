.faqs {
  width: 100%;
  .faqs-container {
    color: white;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 70px 60px;
  }
}

.faq-list {
  color:white;
  width: 100%;
  padding-top: 60px;
}

.faq-drop-down {
  color:bisque;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #ff9305;
  margin-bottom: 15px;
}

.question {
  color:antiquewhite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  padding: 18px 20px;
  transition: all 0.3s ease;
  p{
    color:white;
  }
}

.answer {
  color:wheat;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  max-width: 800px;
}

.show-answer {
  max-height: 1000px;
  padding-bottom: 15px;
}

.arrow {
  transition: all 0.3s ease;
  border: 1px solid #222;
  padding: 8px 11px;
}

.close {
  //transform: rotate(90deg);
  background-color: #222;
  padding: 8px 11px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .faqs .faqs-container {
    padding: 70px 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .faqs .faqs-container {
    padding: 70px 20px;
  }
}

.white-text{
  color:white !important;
}

.title-white{

  color:aliceblue !important;

}

Faq{
  color:white;   
}