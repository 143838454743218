.contact-us {
  width: 100%;
  position: relative;
  background-image: url('../assets/contact.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .contact-us-container {
    width: 100%;
    height: 600px;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    
    .btn {
      width: 100%;
      background-color: #E99A4E;
    }
  }
}

.contact-us-text {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 35px;
  .contact-form {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    .input-box {
      width: 100%;
      input {
        width: 100%;
        border: 1px solid #222;
        margin-bottom: 40px;
        height: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #222;
        padding-left: 16px;
      }

      textarea {
        padding: 5px 16px;
        width: 100%;
        border: 1px solid #222;
        margin-bottom: 40px;
        height: 65px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #222;
      }
    }
  }
}

#contact-us {
  position: absolute;
  top: -35px;
}

.social{
  display: flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  bottom: 30px;
  left: 60px;
  .icon{
    display: flex;
    align-items: center;
    img{
      padding-right: 6px;
    }
  }
}

@media (min-width: 800px) and (max-width: 1050px) {
  .social{
    padding-right: 20px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .contact-us-text {
    width: 100%;
    padding: 20px;
  }

  .contact-us-text .contact-form .input-box input{
    margin-bottom: 20px;
  }

  .contact-us-text .contact-form .input-box textarea{
    margin-bottom: 12px;
  }

  .social{
    padding: 0;
    justify-content: center;
  }
}
