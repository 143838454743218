.card {
  width: 100%;
  
  display: flex;
  flex-direction: column;
}

.card-container{
  margin-bottom: 20px;
}

.card-img{
  width: 100%;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-title {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  p {
    color: #ff8800;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 110% !important;
    //text-transform: uppercase !important;
    padding: 12px 8px !important;
  }
}

.card-dashboard-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 8px;
  border: 1px solid #c8cacc;
}

.card-title-dashboard{
  width: 28%;
}

.edit-delete-btn{
  display: flex;
  justify-content: end;
  align-items: center;
  width: 72%;
}

.edit {
  display: block;
  color: #3687ff;
  padding: 0 15px;
  text-align: center;
  &:hover{
    text-decoration: underline;  
  }
}

.e-color{
  color: #222;
}

.btn-highlight{
  background-color: transparent;
  display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 299px) and (max-width: 799px) {
  .card-title p{
    font-size: 16px !important;
  }
}
