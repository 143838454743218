.comments {
  width: 100%;
  margin-top: 80px;
  .comments-container {
   
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    h2 {
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
      text-transform: capitalize;
      padding-bottom: 40px;
      color: #995000;
    }

    form {
      width: 100%;
      textarea {
        width: 100%;
        height: 90px;
        border: 1px solid #969595;
        padding: 14px;
      }
    }
  }
}

.btn-margin {
  margin-top: 5px;
}



.comment-list {
  width: 100%;
  margin-top: 60px;
  .comment-box {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px 40px 10px;
    .comment-user-name {
      display: flex;
      flex-direction: row;
      p {
        padding: 0 8px 8px 0 !important;
        font-weight: 800;
      }
    }
  }
}

.comment-user-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 299px) and (max-width: 799px) {
  .comments .comments-container h2{
    font-size: 28px;
    padding-bottom: 15px;
  }
}
