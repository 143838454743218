.footer {
  background-color: #222;
  width: 100%;  
}

.footer-container {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  p{
    color: #fff;
    font-size: 14px;
  }
}
.footer-container-social {
  display: flex;
  justify-content: center;
  align-items: center;
  &> div {
    display: flex;
    margin: 0 2rem;
    align-items: center;
  }

  img {
    width: 16px;
  }
  a {
    margin-left: 0.5rem;
    color: white;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .footer-container p{
    font-size: 10px;
  }
}