body{
  background-color: black;

}

.top-expositions {
  width: 100%;
  position: relative;
  .top-expositions-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 70px 60px;
  }
}

@media (min-width: 800px) and (max-width: 1050px) {
  .top-expositions .top-expositions-container {
    padding: 70px 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .top-expositions .top-expositions-container {
    padding: 70px 20px;
  }
}
