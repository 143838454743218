.info-btn{
  
  display: none !important;
 
}
.text-in-info{
  color:white;
}
.title-in-info{
  color:#ff9900;
  text-align: center;
}

.info-inside-picture{
  display: none;
}
.work-of-art {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-left: 4px solid #222;
  margin-bottom: 60px;
}

.obra-box {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.obra-audio{
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px;
  .info-btn{display: none;}
  audio{

    width: 100%; 
    height: 35px;
    margin-top: 6px;
  }
}
.title-inside-picture{
  /*display: none;*/
}


@media (min-width:599px) and (min-width:899px) {
  .show-description{
    display: none;
  }
  .obra-description{
    display: none;
  }
  .title-inside-picture{
    display: none;
  }
  .player-aac{
    position: absolute;
    right: 0px;
    bottom: -36px;
    

  }
  .info-btn{
    display: none;
  }
}

.obra-img {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.pop-up-btn {
  position: absolute;
  bottom: 2%;
  left: 2%;
  background-color: rgba(0, 0, 0, 0.801);
  padding: 8px 12px;
  color: #fff !important;
  cursor: pointer;
  font-size: 13px !important;
  &:hover {
    background-color: #222;
  }
}

.pop-up-ubication{
  position: absolute;
  bottom: 2%;
  right: 2%;
  background-color: rgba(0, 0, 0, 0.801);
  padding: 8px 12px;
  color: #fff !important;
  cursor: pointer;
  font-size: 13px !important;
  img{
    width: 15px;
  }
  &:hover {
    background-color: #222;
  }
}

.map{
  display: none;
  
}

.show-text{
  transition-delay: 600ms;
  color:white;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.925);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  img {
    width: 100%;
    max-width: 650px;
    height: auto;
    object-fit: cover;
    cursor: zoom-in;
    transition: transform .2s;
  }
  span {
    cursor: pointer;
    margin-top: 30px;
    font-size: 16px !important;
    color: #fff !important;
    background-color: #000;
    padding: 8px 10px;
    border-radius: 50px;
    img {
      width: 18px;
    }
  }
}


.show-map{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.925);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  img {
    width: 100%;
    max-width: 650px;
    height: auto;
    object-fit: cover;
    cursor: zoom-in;
    transition: transform .2s;
  }
  span {
    cursor: pointer;
    margin-top: 30px;
    font-size: 16px !important;
    color: #fff !important;
    background-color: #000;
    padding: 8px 10px;
    border-radius: 50px;
    img {
      width: 18px;
    }
  }
}

.zoom{
  transform: scale(1.8);
  cursor: zoom-out;
}

.obra-container {
  width: 60%;
  padding: 10px;
  cursor: pointer;
  .obra-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .title-box {
      p {
        font-weight: 800;
        font-size: 42px;
        line-height: 110%;
        text-transform: uppercase;
        font-family: "Bw Nista International DEMO ExBd";
        padding-bottom: 15px !important;
      }
      span{
        text-transform: uppercase;
        font-weight: 500;
        padding-bottom: 20px;
      }
    }
  }
}

.obra-description {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 0;
  overflow: hidden;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}

.show-description {
  max-height: 1000px;
}

.pop-up {
  display: none;
}

.show-pop-up {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.925);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 10px;
  img {
    width: 100%;
    max-width: 650px;
    height: 100%;
    object-fit: contain;
  }
  span {
    cursor: pointer;
    margin-top: 30px;
    font-size: 16px !important;
    color: #fff !important;
    background-color: #000;
    padding: 8px 10px;
    border-radius: 50px;
    img {
      width: 18px;
    }
  }
}

@media (min-width: 900px) and (max-width: 1050px) {
  .title-inside-picture{
    display: none;
  }
  .player-aac{
    display: none;
  }
  .info-movil{
    display: none;
  }
  .info-btn{
    display: none;
  }
  .obra-container .obra-title .title-box p {
    font-size: 42px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .info-btn{
    
    display: none;
    background-color: #ff9900;
  }
  .title-inside-picture{
    display: none;
  }

  .info-movil{
    display: none;
  }
  .player-aac{
    display: none;
  }
  .obra-container .obra-title .title-box p {
    font-size: 32px;
  }
}

@media (min-width: 299px) and (max-width: 599px) {
  .arrow-hide{
    display: none;
  }
  .obra-description{
    display: none;
  }
  
  .info-btn{
    display: none;
    background-color: violet;
  }

  /*
  .title-inside-picture{
    display: none;
  }*/
  .work-of-art .obra-box {
    flex-direction: column;
    .obra-img {
      width: 100%;
    }
    .obra-container {
      
      width: 100%;
      .obra-title {
        .title-box {
          p {
            font-size: 36px;
          }
        }
      }
    }
    
    .obra-audio{
      width: 100%;
     
      

      @media (min-width: 299px){
        
        
        .player-aac{
            position: absolute;
            left: 35%;
            bottom: 5%;
            width: 100px;
            
            
        }
        .player-normal-display{
        display: none;
        width: 120px;

      }
        .info-inside-picture{
          display: none;
          
        }
        .show-inside-picture{
          display:block;
          position: absolute;
          transition: 250ms;
          width: 100%;
          background-color: black;
          
          .parrafo-inside{
            display: block;
            position: absolute;
            color: white;
            background-color: #000;
            p{
              color:wheat;
            }
          }
          .close-btn{
            display: block;
            position: fixed;
            bottom: 10px;
            size: 18px;
            right: 300px;
          }
        }
        .title-principal{
          display: none;

        }
        .info-movil{
          display: none;
        }
        .info-btn{
          position: absolute;
          bottom: 2%;
          left: 2%;
          background-color: rgba(0, 0, 0, 0.801);
          padding: 8px 12px;
          color: #fff !important;
          cursor: pointer;
          font-size: 13px !important;
          &:hover {
            background-color: #222;
          }
          display: block!important;
          bottom: 40px;
          
          
        }
        

      }

    }
  }
}

.close-showinfo span{
  
  position: fixed;
  top: 0%;
  left: 0%;
  
  
}

.close-showinfo span:hover{
  cursor: pointer;

}
.TextandTitle{
  position: absolute;
}

.img-background{
    position: relative;
    width: auto;
    opacity: 38%;

}