.about-us {
  width: 100%;
  background: #E99A4E;
  padding: 60px 0;
  position: relative;
  .about-us-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 0 0 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.about-us-text {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    max-width: 520px;
    padding-top: 25px;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: white;
  }
  h2 {
    color: white;
  }
  a {
    margin-top: 25px;
    background-color: #fff;
    color: #E99A4E;
    font-weight: 500;
    padding: 15px 18px;
    &:hover{
      text-decoration: underline;
    }

  }
}

.about-us-img {
  width: 45%;
  img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    object-position: left;
  }
}

#about-us{
  position: absolute;
  top: -40px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-us .about-us-container {
    padding: 0px 40px;
    flex-direction: column;
    .about-us-img {
      width: 100%;
    }
    .about-us-text {
      width: 100%;
    }
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .about-us{
    padding: 15px 0;
    .about-us-container {
      padding: 0;
      flex-direction: column;
      .about-us-img {
        width: 100%;
          img {
            object-position: center;  
        }
      }
  } 
    .about-us-text {
      width: 100%;
      padding: 20px;
      p {
        width: 100%;
        max-width: none;
      }
    }
  }
}
