.museums {
  width: 100%;
  .museums-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 120px 60px 30px 60px;
    h2 {
      color:white;
      font-style: normal;
      font-weight: 800;
      font-size: 62px;
      margin-top: 50px;
      //line-height: 120px;
    }
  }
}

@media (min-width: 800px) and (max-width: 1050px) {
  .museums .museums-container {
    padding: 120px 40px 30px 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .museums .museums-container {
    padding: 80px 10px 30px 10px;
  }

  .museums .museums-container h2{
    font-size: 50px;
    line-height: 60px;
  }
}
