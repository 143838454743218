.header {
  width: 100%;
  position: fixed;
  z-index: 1;
  border-bottom: 1px solid #000000;
  color:#E99A4E;
  background-color: #222222;

  .header-container {
    width: 100%;
    height: 60px;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
  }
}

.brand {
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .link {
    color:#E99A4E;
    padding: 0 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
  }
  .login {
    display: flex;
    justify-content: flex-end;
  }
}

.hamburguer {
  display: none;
}

.login-link {
  background-color: #E99A4E;
  color: #fff;
  padding: 2px 19px;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.user-drop-down{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
    align-items: center;
    justify-content: center;
}

.user-name{
  color:#E99A4E;
  font-weight: 100;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}
.log-out{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 150px;
  top: 60px;
  cursor: pointer;
  right: 20px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #ebeaea;
  display: none;
}

.dw-item{
  font-size: 14px;
  background: #fff;
  text-align: center;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:hover{
    background-color: rgb(236, 236, 236);
  }
}

.show-dd{
  display: block;
}

.lang-responsive{
  
  margin-left: 10px
}

@media (min-width: 800px) and (max-width: 1050px) {
  .header .header-container {
    padding: 0 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .header .header-container {
    padding: 0 5px 0 20px;
  }
  .hamburguer {
    display: flex;
  }

  .brand {
    width: 50%;
  }

  .nav-menu {
    background-color: #383838;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    justify-content: normal;
    overflow: hidden;
    transform: translateX(100%);
    transition: all 0.5s ease;
    border-top: 1px solid rgb(235, 234, 234);
    .link {
      width: 100%;
      flex-direction: column;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
    }
    .login {
      width: 100%;
      justify-content: center;
      margin: 20px 0;
    }
  }

  .show-menu {
    max-height: 1000px;
    transform: translateX(0);
  }

  .lang-responsive{
    padding: 10px 0;
  }

  .user-drop-down{
    width: 100%;
    text-align: center;
    position: relative;
    justify-content: flex-start;
    height: auto;
    background-color: #a7a7a7;
  }

  .user-name{
    text-transform: uppercase;
    font-size: 16px;
    padding: 15px;
    width: 100%;
  }

  .log-out{
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    font-size: 16px;
    width: 100%;
  }
}
