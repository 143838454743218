.cover {
  width: 100%;
  .cover-container {
    width: 100%;
    height: 100vh;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
  }

  .btn {
    background-color: #E99A4E;
  }
}

.cover-img {
  width: 100vw;
  position: relative;
  //background-image: url(../assets/cover2.jpg);
  //background-repeat: no-repeat;
  //background-size: cover;
}
.cover-title {
  max-width: 1600px;
  position: absolute;
  height: 100vh;
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h1 {
    font-weight: 800;
    font-size: 46px;
    line-height: 58px;
    max-width: 800px;
    text-transform: uppercase;
    font-family: "Bw Nista International DEMO ExBd";
    margin-bottom: 1.5rem;
    color: whitesmoke;

  }
  p {
    color:white;
    max-width: 520px;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 4rem;
    font-weight: 500;

  }
}

.tablet{
  display: none;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .cover-title {
    padding: 0 40px;
  }
}

@media (min-width: 851px) {
  .desktop{
    display: block;
  }
  .tablet{
    display: none;
  }
  .mobile{
    display: none;
  }
}

@media (min-width: 620px) and (max-width: 850px) {
  .desktop{
    display: none;
  }
  .tablet{
    display: block;
  }
  .mobile{
    display: none;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .cover-title {
    padding: 0 20px;
    h1{
      font-size: 19px;
    line-height: 30px;
    }
    p{
      font-size: 14px;
      margin-bottom: 2rem;
      line-height: 20px;
    }
  }
}

@media (min-width: 299px) and (max-width: 619px) {
  .desktop{
    display: none;
  }
  .tablet{
    display: none;
  }
  .mobile{
    display: block;
  }
}
