.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}

.login-form {
  color:#E99A4E;
  width: 35%;
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  h3 {
    color:#E99A4E;
    margin-bottom: 30px;
  }
}

.log-form-style {
  color:aquamarine;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-login {
  margin: 0 auto;
  background-color: #E99A4E;
  color: white;
  padding: 12px 40px;
}

.login-options {
  color:aquamarine;
  padding: 30px 0 10px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.signin-link {
  color:aquamarine;
  padding-top: 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.desktop-image {
  width: 65%;
  height: 100%;
  background-color: grey;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.forgot-password{
  width: 100%;
  max-width: 450px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  h3{
    padding-bottom: 8px;
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  .login-form {
    width: 45%;
  }
  .desktop-image {
    width: 55%;
  }
}

@media (min-width: 299px) and (max-width: 849px) {
  .login-form {
    width: 100%;
  }
  .desktop-image {
    display: none;
  }
}
