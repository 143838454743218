.delete-btn{
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 30px;
}

.delete-btn-dd{
  text-align: end;
}

.delete{
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  font-size: 16px;
  color: red;
  padding: 0 8px;
  height: 28px;
  border-radius: 2px;
}

.show-delete{
  max-height: 1000px;
}