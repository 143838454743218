* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  color: #222;
  font-family: "Bw Nista International DEMO";
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

.btn {
  background-color: #222;
  color: white;
  padding: 8px 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

h2 {
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Bw Nista International DEMO ExBd";
}

.mav-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  margin-top: 30px;
  column-gap: 1%;
}

.none {
  display: none;
}

.cpanel-label {
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
}

.cpanel-input {
  width: 100%;
  margin-bottom: 15px;
  height: 40px;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 4px;
}

.cpanel-file {
  margin-bottom: 25px;
}

.cpanel-select {
  margin-bottom: 15px;
  height: 40px;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 4px;
}

.cpanel-textarea {
  height: 95px;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #999;
  border-radius: 4px;
  margin-bottom: 15px;
}

.input-names {
  width: 100%;
  display: flex;
  justify-content: space-between;
  input {
    width: 49%;
  }
}

.error {
  color: red;
}

.btn-lang{
  border-radius: 8px;
  padding: 6px;
  background-color: #fff;
}

.alert-form{
  font-size: 12px !important;
  color: red !important;
  padding-bottom: 3px !important;
}

.blocked{
  cursor: not-allowed;
}

.go-back-front{
  display: flex ;
  align-items: center ;
  background-color: #4b4b4b ;
  padding: 4px;
  border-radius: 4px;
  p{
    color:#fff;
    padding-left: 5px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding-bottom: 0 !important;
  }
}

.empty-list-msg{
  width: 100%;
  text-align: center;
  width: 100%;
  text-align: center;
  padding: 35px;
  font-style: italic;
}

.spinner-p{
  margin: 80px 0;
}



.like-btn{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #F69739;
  display: flex;
  align-items: center;
  padding: 0 8px;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: transparent;
  img{
    padding-right: 3px;
  }
}

.hl-button{
  cursor: pointer;
}

.parrafo {
  white-space: break-spaces;
}

.audio-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #999;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 14px;
  div{
    cursor: pointer;
  }
}

.audio-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  img{
    padding-right: 5px;
  }
}

@media (min-width: 650px) and (max-width: 1050px) {
  .mav-grid {
    grid-template-columns: 33% 33% 33%;
  }
}

@media (min-width: 450px) and (max-width: 649px) {
  .mav-grid {
    grid-template-columns: 49% 49%;
  }
}

@media (min-width: 299px) and (max-width: 449px) {
  .mav-grid {
    grid-template-columns: 100%;
  }
}


@media (min-width: 299px) and (max-width: 799px) {
  h2{
    font-size: 32px;
    line-height: 39px;
  }
}
