.c-panel-dashboard {
  width: 100%;
}

.dashboard-container {
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  display: flex;
  flex-direction: row;
}

.navbar {
  width: 25%;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #f6f7f8;
  border-right: 1px solid #c8cacc;
  padding: 25px 40px;
  .navbar-links {
    width: 100%;
    padding: 10px 0;
    font-weight: 400;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    color: #222;
    &:hover {
      color: #aaacac;
    }
  }
  .to {
    display: block;
    padding: 0;
    max-width: 100%;
  }
}
.navbar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div h5 {
    border-bottom: 1px solid #c8cacc;
    padding-bottom: 5px;
    font-size: 12px;
  }
}


.go-home-btn{
  display: flex;
}

.navbar-links-container{
  padding-top: 10px;
}

.go-home-link{
  color: #3687ff;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3687ff;
  padding: 8px 0;
  width: 100%;
  &:hover{
    text-decoration: underline;
  }
}

.log-out div {
  background-color: #323335;
  color: #aaacac;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 17px;
}

.general-dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background-color: #f6f7f8;
}

.card-dashboard-container {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  padding: 20px;
  background: #fff;
  border: 1px solid #c8cacc;
  border-radius: 4px;
  h3 {
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
  }
}

.btn-dashboard-container {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-dashboard {
  color: #fff;
  background-color: #3687ff;
  padding: 12px 20px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all .3s ease;
  &:hover {
    background-color: #3660ebe3;
  }
}

.cpanel-form-page {
  background-color: #eaeaea;
  width: 100%;
  margin-bottom: 30px;
}

.cpanel-sub-header {
  width: 100%;
  background-color: #eaeaea;
  margin-top: 60px;
  position: fixed;
  z-index: 1;
  div {
    width: 100%;
    height: 60px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      color: #fff;
      color: #1a1c1e;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 8px;
      font-weight: 500;
      font-size: 16px;
    }
    img {
      width: 16px;
      padding-top: 5px;
    }
  }
}

.cpanel-form-container {
  background-color: #eaeaea;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 160px;
  position: relative;
  h3 {
    padding-bottom: 25px;
    font-size: 21px;
  }
  .cpanel-label{
    font-weight: 500;
  }
}

.top {
  padding-top: 0;
  max-width: none;
}

.cpanel-form {
  display: flex;
  flex-direction: column;
}

.obra-list-title-page{
  padding-bottom: 15px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
}