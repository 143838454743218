.title-box p{
  color: #ff8800;
}
.exposition {
  background-color: black;
  .parrafo{
    color: #fafafa;
  }
  width: 100%;
  .exposition-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 120px 60px 30px 60px;
    h2 {
      color: #ff8000;
      font-style: normal;
      font-weight: 800;
      font-size: 50px;
      margin-top: 50px;
      margin-bottom: 25px;
      text-transform: none;
    }
    .font {
      color: #ff8800;
      font-size: 50px;
      //line-height: 2px;
    }
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      padding-bottom: 30px;
    }
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #fafafa;
      display: flex;
      align-items: center;
      img {
        padding-right: 5px;
      }
    }
  }
}

.recorrido {
  width: 100%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.museum-data{
  display: flex;
  align-items: center;
  span{
    font-size: 13px !important;
  }
  .gap{
    padding-right: 10px;
  }
}

.preview-title{
  color: #f6f7f8;
  background-color: #3687ff;
  width: 100%;
  text-align: center;
  font-size: 21px;
  line-height: 50px;
}

.exposition-preview{
  padding-top: 60px !important;
}

.resume{
  color: #fafafa;
  width: 100%;
  max-width: 750px;
  margin:  0 auto;
}

.resume p{
  color: #fafafa;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .exposition .exposition-container {
    padding: 120px 40px 30px 40px;
  }

  .obra-container .obra-title .title-box p {
    color: #ff8800;
    font-size: 42px;
  }

}

@media (min-width: 299px) and (max-width: 799px) {
  .exposition .exposition-container {
    padding: 80px 10px 30px 10px;
    h2{
      font-size: 32px;
    }
    p{
      font-size: 14px;
      line-height: 24px;
    }
  }

  .museum-data{
    flex-direction: column;
    align-items: flex-start;
    span{
      padding-bottom: 15px;
      align-items: flex-start !important;
      line-height: 16px !important;
    }
  }
  .exposition .exposition-container .font{
    font-size: 32px;
    line-height: 46px;
  }

  .recorrido{
    margin-top: 45px;
  }
   
  .museum-data span{
    font-size: 13px !important;
  }
}
