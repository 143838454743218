.header-back-office {
  width: 100%;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    position: fixed;
    z-index: 1;
    border-bottom: 1px solid #c8cacc;
  
}

.user-logout-backoffice{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.user-name-backoffice{
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}

.logout-btn-backoddice{
  display: flex;
  position: absolute;
  right: 0;
  top: 59px;
  font-size: 14px;
  overflow: hidden;
  height: 35px;
  background-color: #fff;
  width: 160px;
  align-items: center;
  justify-content: center;
  border: 1px solid #c8cacc;
  display: none;
  transition: all .3s ease;
  &:hover{
    background-color: #c8cacc;
  }
}

.show-lbt{
  display: block;
}

.brand-backoffice{
  filter: grayscale(1);
  img{
    width: 130px;
  }
}