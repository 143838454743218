@font-face {
    font-family: 'Bw Nista International DEMO Bd';
    src: url('BwNistaInternationalDEMO-Bold.eot');
    src: url('BwNistaInternationalDEMO-Bold.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Bold.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Bold.woff') format('woff'),
        url('BwNistaInternationalDEMO-Bold.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Bold.svg#BwNistaInternationalDEMO-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO Black It';
    src: url('BwNistaInternationalDEMO-BlackItalic.eot');
    src: url('BwNistaInternationalDEMO-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-BlackItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-BlackItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-BlackItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-BlackItalic.svg#BwNistaInternationalDEMO-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO';
    src: url('BwNistaInternationalDEMO-Black.eot');
    src: url('BwNistaInternationalDEMO-Black.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Black.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Black.woff') format('woff'),
        url('BwNistaInternationalDEMO-Black.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Black.svg#BwNistaInternationalDEMO-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO Bd It';
    src: url('BwNistaInternationalDEMO-BoldItalic.eot');
    src: url('BwNistaInternationalDEMO-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-BoldItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-BoldItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-BoldItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-BoldItalic.svg#BwNistaInternationalDEMO-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO Light It';
    src: url('BwNistaInternationalDEMO-LightItalic.eot');
    src: url('BwNistaInternationalDEMO-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-LightItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-LightItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-LightItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-LightItalic.svg#BwNistaInternationalDEMO-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO ExBd';
    src: url('BwNistaInternationalDEMO-ExtraBold.eot');
    src: url('BwNistaInternationalDEMO-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-ExtraBold.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-ExtraBold.woff') format('woff'),
        url('BwNistaInternationalDEMO-ExtraBold.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-ExtraBold.svg#BwNistaInternationalDEMO-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO';
    src: url('BwNistaInternationalDEMO-Medium.eot');
    src: url('BwNistaInternationalDEMO-Medium.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Medium.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Medium.woff') format('woff'),
        url('BwNistaInternationalDEMO-Medium.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Medium.svg#BwNistaInternationalDEMO-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO';
    src: url('BwNistaInternationalDEMO-Light.eot');
    src: url('BwNistaInternationalDEMO-Light.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Light.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Light.woff') format('woff'),
        url('BwNistaInternationalDEMO-Light.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Light.svg#BwNistaInternationalDEMO-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO ExBd It';
    src: url('BwNistaInternationalDEMO-ExtraBoldItalic.eot');
    src: url('BwNistaInternationalDEMO-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-ExtraBoldItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-ExtraBoldItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-ExtraBoldItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-ExtraBoldItalic.svg#BwNistaInternationalDEMO-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO';
    src: url('BwNistaInternationalDEMO-Thin.eot');
    src: url('BwNistaInternationalDEMO-Thin.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Thin.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Thin.woff') format('woff'),
        url('BwNistaInternationalDEMO-Thin.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Thin.svg#BwNistaInternationalDEMO-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO Medium It';
    src: url('BwNistaInternationalDEMO-MediumItalic.eot');
    src: url('BwNistaInternationalDEMO-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-MediumItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-MediumItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-MediumItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-MediumItalic.svg#BwNistaInternationalDEMO-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO It';
    src: url('BwNistaInternationalDEMO-RegularItalic.eot');
    src: url('BwNistaInternationalDEMO-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-RegularItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-RegularItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-RegularItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-RegularItalic.svg#BwNistaInternationalDEMO-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO Thin It';
    src: url('BwNistaInternationalDEMO-ThinItalic.eot');
    src: url('BwNistaInternationalDEMO-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-ThinItalic.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-ThinItalic.woff') format('woff'),
        url('BwNistaInternationalDEMO-ThinItalic.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-ThinItalic.svg#BwNistaInternationalDEMO-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Nista International DEMO';
    src: url('BwNistaInternationalDEMO-Regular.eot');
    src: url('BwNistaInternationalDEMO-Regular.eot?#iefix') format('embedded-opentype'),
        url('BwNistaInternationalDEMO-Regular.woff2') format('woff2'),
        url('BwNistaInternationalDEMO-Regular.woff') format('woff'),
        url('BwNistaInternationalDEMO-Regular.ttf') format('truetype'),
        url('BwNistaInternationalDEMO-Regular.svg#BwNistaInternationalDEMO-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

